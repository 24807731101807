import * as React from "react"

import Layout from "../components/layout"

import * as Styles from '../styles/layout.module.scss'


// markup
const NotFoundPage = ({ pageContext }) => {
  
  var translationContext = { locale: 'de',
  defaultMenu: false };
  return (
    <Layout title={"Page not Found"}
      pageContext={pageContext}
      translationContext={translationContext}>
        
             <div class={Styles.singleTopicLayout}>
               <main>
             <div class={Styles.centeredLayout}>



      <h1>Seite nicht gefunden</h1>
      <p>Wir konnten die Seite mit der angegebenen URL leider nicht finden. </p>
      <p>Wenn Sie einen Link geklickt haben und hier gelandet sind, bitten wir Sie um eine kurze Nachricht per E-Mail an <a href="mailto://website@brandeis.de"> website@brandeis.de</a>, worin Sie uns bitte mitteilen, wo der fehlerhafte Link auf unserer Seite war. Wir arbeiten stets daran, unsere Seite zu verbessern... </p>
      
      </div>
      </main>
      </div>
      </Layout>
      

  )
}

export default NotFoundPage
